// ----------------------------------- FONTS

@import url("https://use.typekit.net/ddt5tnq.css");

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');


$body-font: "Nunito", Arial, sans-serif;

$heading-font: 'bodoni-egyptian-pro', 'Bodoni MT', Garamond, 'Times New Roman', serif;

// ----------------------------------- VARIABLES

$menu-colour: #1D1D1B;
$ql-colour: #1D1D1B;
$ql-colour-text: #FFEF00;
$social-colour: #1D1D1B;
$strap-colour: #FFEF00;

$welcome-bg: #1D1D1B;
$welcome-text: #fff;
$r-more-txt: #FFEF00;

$news-bg: #e5e5e5;
$r-more-txt-news: #1D1D1B;
$news-title: #1D1D1B;
$news-dd: #1D1D1B;
$news-pp: #b3b3b3;
$news-p-title: #fff;

$diary-bg: #fff;
$diary-title: #1D1D1B;
$diary-dd: #1D1D1B;
$r-more-txt-diary: #fff;

$curr-bg: #009FE3;
$curr-text: #fff;

$footer-bg: #1D1D1B;
$details-span: #FFEF00;

$link-color: deepskyblue;

$h1-color: $menu-colour;
$h2-color: $ql-colour;
$h3-bg-color: $menu-colour;
$h4-bg-color: $menu-colour;

// ----------------------------------- INSIDE PAGES VARIABLES

$dd-bg: $menu-colour;
$dd-color: #111;

// ----------------------------------- MEANMENU VARIABLES

$menu-main-bg: darken($menu-colour, 8%);
$menu-drop-bg: lighten($menu-main-bg, 2%);

// ----------------------------------- SIDELIST HOVER STYLES

$col-right-bg: $menu-colour;
$col-right-outline: #fff;
$rp-col-hov: rgba(255, 255, 255, .1);

// background-color: rgba(255, 255, 255, .1);

// ----------------------------------- BLOCKQUOTES

$blockquote-background-color: #fff;
$blockquote-border-color: $menu-colour;
$blockquote-color: $menu-colour;
$blockquote-speech-mark-color: $menu-colour;
$blockquote-font-size: 1em;

// ------------------------- TO @EXTEND THROUGHOUT

.read-more {
	display: block;
	width: 230px;
	height: 69px;
	line-height: 69px;
	text-align: center;
	float: right;
	margin: 50px 0 0 0;
	font-size: 22px;
	transition: .3s;
	position: relative;
	letter-spacing: 0.7px;

	span {
		@include center;
		color: $r-more-txt;
		width: 100%;
	}

	&:hover {
		span {
			letter-spacing: 0.1em;
		}
	}
}


// ----------------------------------- BODY

body {
	text-align: center;
	padding: 0;
	margin: 0;
	position: relative;
	overflow-x: hidden;
	font-family: $body-font;
	font-size: 20px;

	h1,
	h2,
	h3,
	h4,
	h5 {
		font-family: 'bodoni-egyptian-pro', 'Bodoni MT', Garamond, 'Times New Roman', serif;
	}
}

.body_1840 * {
	box-sizing: border-box;
}

body a {
	color: $link-color;
	text-decoration: none;
	transition: .3s;
}

body a:hover,
body a:focus {
	text-decoration: none;
}

// ----------------------------------- WRAPPER

#wrapper {
	margin: 0 auto;
	text-align: center;
	max-width: 2000px;
	overflow: hidden;
}

.wrapper {
	width: 990px;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
	position: relative;
}

.wrapper-inside {
	width: 1500px;
	max-width: 90%;
	margin: 0 auto;
	@extend .clearfix;
	position: relative;
}

// fix home page wrapper called in t.asp
.body_1840 .wrapper-inside {
	width: 100%;
	max-width: 100%;
	padding: 0;
}

// ----------------------------------- MAIN HOLDER

#mainholder {
	width: 100%;
	margin: 0 auto;
	padding: 30px 0 30px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
	position: relative;

	&:before {
		@include before(100%, 40px);
		right: 0;
		left: 0;
		margin: 0 auto;
		top: -38px;
		background: url(/i/design/white-curve-full.png) no-repeat center;
		z-index: 99;

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}
}

// home page alternate
.body_1840 #mainholder {
	padding: 0;
	margin: 0;

	&:before {
		display: none;
	}

	* {
		transition: .3s;
	}
}

// ----------------------------------- CONTENT COLUMNS

//Content 1
.Content1Column {
	width: 1360px;
	max-width: 95%;
	margin: 0 auto 50px;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	background: rgba(255, 255, 255, .95);
	@extend .clearfix;

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 2
.Content2Column {
	width: calc(98% - 350px);
	margin: 0 0 50px 2%;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	display: inline-block;
	float: left;
	background: rgba(255, 255, 255, .95);
	@extend .clearfix;
	// position: relative;
	// z-index: 99;
	// margin: -100px 0 50px 2%;

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 3
// .Content3Column {
// 	width: 430px;
// 	margin: 0;
// 	padding: 0;
// 	float: left;
// 	min-height: 350px;
// 	text-align: left;

// 	img {
// 		max-width: 100%;
// 		height: auto;
// 	}
// }

#mainholder {

	tr,
	td {
		border: 1px solid #BFBFBF;
	}

	td {
		padding: 5px;
	}

	.tablenoborderpadded {
		border: 0 !important;

		tr,
		td {
			border: 0px !important;
		}
	}
}

.HomeAlert .Content1Column {
	float: none;
	margin: 0 auto 50px;
}

.Content1Column,
.Content2Column {

	a img,
	img a,
	img,
	p a img {
		border: 0;
		border-bottom: 0;
		text-decoration: none;
		vertical-align: middle;
	}

	h1 {
		font-size: 60px;
		margin: 0 0 20px 0;
		color: $h1-color;
		line-height: 1.2;

		@media only screen and (max-width: 1800px) {
			font-size: 55px;
		}

		@media only screen and (max-width: 1500px) {
			font-size: 45px;
		}

		@media only screen and (max-width: 1350px) {
			font-size: 40px;
		}

		@media only screen and (max-width: 990px) {
			font-size: 35px;
		}
	}

	h2 {
		font-size: 35px;
		margin: 25px 0 20px 0;
		color: $h2-color;
		line-height: 1.3;

		@media only screen and (max-width: 1800px) {
			font-size: 42px;
		}

		@media only screen and (max-width: 1500px) {
			font-size: 40px;
		}

		@media only screen and (max-width: 1350px) {
			font-size: 38px;
		}

		@media only screen and (max-width: 990px) {
			font-size: 30px;
		}
	}

	h3 {
		font-size: 25px;
		margin: 25px 0 10px 0;
		padding: 5px 10px 2px 20px;
		background: $h3-bg-color;
		color: #fff;
		border-radius: 8px;

		@media only screen and (max-width: 1800px) {
			font-size: 23px;
		}

		@media only screen and (max-width: 1500px) {
			font-size: 20px;
		}

		@media only screen and (max-width: 1350px) {
			font-size: 18px;
		}

		@media only screen and (max-width: 990px) {
			font-size: 16px;
		}
	}

	h4 {
		@extend h3;
		background-position: 10px center;
		background-repeat: no-repeat;
		padding-left: 30px;
		background-color: $h4-bg-color;
	}

	h4.open {
		background-image: url(/i/design/expanding-faq/minus-white.png);
	}

	h4.closed {
		background-image: url(/i/design/expanding-faq/plus-white.png);
	}

	h5 {
		font-size: 12px;
	}



	h6,
	small {
		font-size: 10px;
	}

	h1+h2 {
		margin-top: 0;
		border: 0;
		padding: 0;
	}

	h1+p,
	h2+p,
	h3+p {
		margin-top: 0;
	}

	p {
		margin: 12px 0;
		padding: 0;
	}

	li {
		margin: 2px 0;
		padding: 0 0 0 23px;
		background: url(/i/icons/bullet_black.png) no-repeat top 6px left 3px;
	}

	hr {
		height: 1px;
		border-width: 0;
		color: #f2f2f2;
		background-color: #f2f2f2;
	}
}

// ----------------------------------- COLUMN LEFT & COLUMN RIGHT

.ColumnLeft {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: left;
}

.ColumnRight {
	width: 300px;
	margin: 0 20px 0 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	display: inline-block;
	float: right;
	box-sizing: border-box;
	border-radius: 20px 10px 30px 10px;
	background: $col-right-bg;
	outline: 2px solid $col-right-outline;
	outline-offset: -5px;
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack

// ----------------------------------- RELATED PAGES

#SideHeadingPages {
	position: relative;
	overflow: hidden;
	z-index: 1;
	padding: 20px 5%;

	h2 {
		display: block;
		text-align: center;
		font-size: 40px;
		padding: 10px 0;
		margin: 0;
		color: $strap-colour;
	}

	ul.SideList {
		padding: 0 0 30px 0;

		li {
			display: block;
			width: 100%;
			text-align: left;

			a.child {
				background: url(/i/design/svg/ic-related-pages.svg) left 20px center no-repeat;
				padding-left: 40px !important;
			}

			a {
				padding: 7px 20px;
				font-size: 22px;
				color: #fff;

				&:hover {
					background-color: $rp-col-hov;
				}
			}
		}
	}
}