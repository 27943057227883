// -----------------------------------//
// NEWS
// -----------------------------------//

#news-sec {
 padding: 80px 0 90px;
 background: $news-bg;
 position: relative;

 @media only screen and (max-width: 990px) {
  padding: 50px 0;
 }

 &:before {
  @include before(100%, 40px);
  right: 0;
  left: 0;
  margin: 0 auto;
  top: -2px;
  background: url(/i/design/white-curve-full.png) no-repeat center;
  transform: scaleY(-1);
  z-index: 99;
 }

 h2 {
  text-align: left;
  margin: 0 0 70px 0;
  color: $news-title;
  font-size: 65px;


  @media only screen and (max-width: 990px) {
   text-align: center;
   margin: 0 auto 30px;
   font-size: 40px;
  }
 }
}

//News
#SideHeadingNews {

 ul.SideList {
  text-align: center;
  margin: 0 auto;

  li {
   display: inline-block;
   width: 310px;
   max-width: 95%;
   height: 225px;
   overflow: hidden;
   position: relative;
   transition: .3s;

   @media only screen and (max-width: 990px) {
    margin: 10px auto 20px;
   }

   &:nth-of-type(2) {
    margin: 0 22px;

    @media only screen and (max-width: 990px) {
     margin: 10px;
    }
   }

   &:before {
    @include before(100%, 50px);
    background: url(/i/design/btn-curve-grey.png) no-repeat top center;
    top: -1px;
    left: 0;
    right: 0;
    margin: 0 auto;
    pointer-events: none;
    user-select: none;
    z-index: 99;
   }

   &:after {
    @include before(100%, 50px);
    background: url(/i/design/btn-curve-grey-flip.png) no-repeat bottom center;
    bottom: -1px;
    left: 0;
    right: 0;
    margin: 0 auto;
    pointer-events: none;
    user-select: none;
    z-index: 99;
   }

   img {
    @include box(100%);
    object-fit: cover;
    position: relative;
    z-index: 98;
    transition: .3s;
    filter: brightness(95%);
   }

   p.date {
    position: absolute;
    height: 40px;
    bottom: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    background: $news-p-title;
    text-transform: uppercase;
    font-size: 20px;
    padding: 0 0 20px;
    transition: .3s;

    color: $news-dd;

    span {
     display: inline-block;
     transition: .3s;
    }
   }

   p.title {
    background: $news-p-title;
    position: absolute;
    z-index: 99;
    left: 0;
    bottom: 40px;
    padding: 20px 20px 10px;
    font-size: 18px;
    line-height: 1.3;
    color: $news-pp;
    width: 100%;
    transition: .3s;

    // Truncate
    span {
     overflow: hidden;
     display: -webkit-box;
     -webkit-line-clamp: 2;
     -webkit-box-orient: vertical;
     height: 40px;
     transition: .3s;
    }
   }

   &:hover {
    img {
     transform: translateY(-50px);
    }
   }
  }

  &:hover li:not(:hover) {
   opacity: .5;
   transform: scale(.95);
   filter: blur(2px);
  }
 }

 .btn-wrap {
  position: absolute;
  right: 0;
  top: 10px;
  width: 500px;

  @media only screen and (max-width: 990px) {
   text-align: center;
   position: relative;
   left: 0;
   right: 0;
   margin: 0 auto;
   width: auto;
  }

  .more {
   @extend .read-more;
   float: none;
   margin: 0 0 0 25px;
   display: inline-block;
   width: 220px;

   span {
    color: $r-more-txt-news;
   }

   &:hover {
    transform: translateY(-5px);
   }

   @media only screen and (max-width: 990px) {
    margin: 5px;
   }
  }
 }
}


// -----------------------------------//
// DIARY
// -----------------------------------//

#diary-sec {
 padding: 90px 0 100px;
 background: $diary-bg url(/i/design/dd-ic.png) no-repeat left 5% center;
 position: relative;

 @media only screen and (max-width: 1500px) {
  background: $diary-bg;
 }

 @media only screen and (max-width: 990px) {
  padding: 50px 0 80px;
 }

 &:before {
  @include before(100%, 86px);
  right: 0;
  left: 0;
  margin: 0 auto;
  top: -2px;
  background: url(/i/design/grey-curve-full.png) no-repeat center;
  z-index: 99;
 }

 h2 {
  text-align: left;
  margin: 0 0 90px 0;
  color: $diary-title;
  font-size: 65px;

  @media only screen and (max-width: 990px) {
   text-align: center;
   margin: 0 auto 50px;
   font-size: 40px;
  }
 }
}

//diary
#SideHeadingDiary {

 ul.SideList {
  text-align: center;
  margin: 0 auto;

  li {
   display: inline-block;
   width: 310px;
   max-width: 95%;
   height: 170px;
   position: relative;
   transition: .3s;
   background: url(/i/design/dd-bg.png) no-repeat center;

   @media only screen and (max-width: 990px) {
    margin: 10px 10px 40px;
   }

   &:nth-of-type(2) {
    margin: 0 22px;

    @media only screen and (max-width: 990px) {
     margin: 10px auto 40px;
    }
   }

   p.date {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 200px;
    text-align: center;
    top: -30px;
    z-index: 99;
    background: $diary-bg;

    font-size: 51px;
    color: $diary-dd;
    transition: .3s;

    span {
     display: inline-block;
     transition: 0s;
    }
   }

   p.title {
    padding: 60px 20px 0;
    font-size: 20px;
    line-height: 1.3;
    font-weight: 500;
    color: $diary-dd;
    width: 100%;
    transition: .3s;

    // Truncate
    span {
     overflow: hidden;
     display: -webkit-box;
     -webkit-line-clamp: 2;
     -webkit-box-orient: vertical;
     height: 45px;
     transition: .3s;
    }
   }

   &:hover {

    p.date {
     letter-spacing: 0.1em;
    }
   }
  }

  &:hover li:not(:hover) {
   opacity: .5;
   transform: scale(.95);
   filter: blur(2px);
  }
 }

 .btn-wrap {
  position: absolute;
  right: 0;
  top: 10px;
  width: 500px;

  @media only screen and (max-width: 990px) {
   text-align: center;
   position: relative;
   left: 0;
   right: 0;
   margin: -20px auto 0;
   width: auto;
  }

  .more {
   @extend .read-more;
   float: none;
   margin: 0 0 0 25px;
   display: inline-block;
   width: 220px;

   span {
    color: $r-more-txt-news;
   }

   &:hover {
    transform: translateY(-5px);
   }

   @media only screen and (max-width: 990px) {
    margin: 5px;
   }
  }
 }
}