// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_1840 #slider {
  height: 100vh;
  max-height: 1080px;
  min-height: 650px;
  position: relative;

  @media only screen and (max-width: 990px) {
    height: 700px;
    min-height: 700px;
  }

  &:after {
    @include before(100%, 750px);
    background: url(/i/design/welcome-top.png), linear-gradient(0deg, rgba(#000, 1) 0%, rgba(#000, 0) 100%);
    background-repeat: no-repeat;
    background-position: bottom center, bottom center;
    bottom: -2px;
    left: 0;
  }
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  min-height: 550px;
  position: relative;
  z-index: 1;

  @media only screen and (max-width: 990px) {
    height: 450px;
    min-height: 450px;
  }

  &:before {
    @include before(100%, 450px);
    background: linear-gradient(180deg, rgba(#000, 1) 0%, rgba(#000, 0) 100%);
    top: 0;
    left: 0;
  }
}

.backstretch {
  position: relative;

  @media only screen and (max-width: 990px) {

    &:before {
      position: absolute;
      content: '';
      background: rgba(0, 0, 0, .2);
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }

  img {
    top: 0px !important;
    animation: scale 20s ease forwards infinite;
  }

  @keyframes scale {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(1.2);
    }
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 60px;
  margin: 0 auto;
  text-align: center;
  color: $strap-colour;
  font-size: 75px;
  z-index: 99;
  line-height: 1.2;


  @media only screen and (max-width: 1800px) {
    font-size: 70px;
  }

  @media only screen and (max-width: 1500px) {
    font-size: 55px;
  }

  @media only screen and (max-width: 1350px) {
    font-size: 35px;
  }

  @media only screen and (max-width: 990px) {
    font-size: 25px;
  }
}

#scroller {
  width: 69px;
  position: absolute;
  right: 5%;
  top: 90%;
  margin: 0 auto;
  opacity: 0;
  animation: bob 1s 2s forwards;
  z-index: 999;

  @media only screen and (max-width: 990px) {
    display: none;
  }
}

@keyframes bob {
  0% {
    top: 90%
  }

  80% {
    top: 92%;
    opacity: 1;
  }

  100% {
    top: 90%;
    opacity: 1;
  }
}