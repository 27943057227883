header {
    padding: 30px 0 0 0;

    @media only screen and (max-width: 1800px) {
        padding: 40px 0 0 0;
    }

    @media only screen and (max-width: 1500px) {
        padding: 50px 0 0 0;
    }

    @media only screen and (max-width: 990px) {
        padding: 120px 0 0 0;
    }
}

header>.wrapper {
    padding: 0;
    position: relative;
    z-index: 60;
    width: 85%;
}

header h1 {
    margin: 0;
    background: none;
}

#logo-index {
    margin: 0;
    padding: 0;
    top: 50px;
    position: absolute;

    @media only screen and (max-width: 1800px) {
        width: 200px;
    }

    @media only screen and (max-width: 1500px) {
        width: 180px;
    }

    @media only screen and (max-width: 1350px) {
        width: 150px;
    }

    @media only screen and (max-width: 990px) {
        margin: 0 auto;
        position: static;
        top: auto;
    }

    a {
        span {}

        img#logo {
            display: block;
            margin: 0 auto;
            float: left;
        }
    }
}

.trust-link {
    // position: absolute;
    position: fixed;
    top: 90px;
    right: 0;
    z-index: 9999;

    @media only screen and (max-width: 1800px) {
        width: 80px;
    }

    @media only screen and (max-width: 1500px) {
        width: 70px;
    }

    @media only screen and (max-width: 990px) {
        display: none;
    }

    @media only screen and (max-height: 650px) {
        display: none;
    }
}

// ----------------------------------- SOCIAL DOCK

#social-dock {
    background: $social-colour;
    position: absolute;
    right: 0;
    top: calc(50% - 100px);
    width: 70px;
    padding: 40px 0;
    z-index: 9999;
    min-height: 290px;

    @media only screen and (max-width: 1800px) {
        width: 60px;
    }

    @media only screen and (max-width: 1500px) {
        width: 50px;
    }

    @media only screen and (max-width: 990px) {
        top: 100px;
        position: absolute;
        padding: 20px 0;
        min-height: unset;
        z-index: 999;
    }

    // @media only screen and (max-width: 650px) {
    //  top: 245px;
    //  padding: 20px 0;
    //  min-height: unset;
    //  z-index: 99;
    // }

    &:before {
        @include before(19px, 290px);
        @include center;
        left: -9px;
        z-index: 99;
        background: url(/i/design/social-bit.png) no-repeat center;

        @media only screen and (max-width: 650px) {
            display: none;
        }
    }

    li {
        display: block;
        padding: 0;
        background: none;
        margin: 0 auto 30px;
        @include box(30px);
        position: relative;

        @media only screen and (max-width: 650px) {
            margin: 0 auto 15px;
        }

        &:last-of-type {
            margin: 0 auto;
        }

        &:first-of-type,
        &:nth-of-type(2) {
            @include box(30px);
        }

        img {
            display: inline-block;
            margin: 0 auto;
            vertical-align: top;
        }

        a {
            display: block;
            @include box(100%);
        }

        .tooltip {
            position: absolute;
            width: 180px;
            padding: 10px 10px 10px 20px;
            border-radius: 5px;
            top: 50%;
            transform: translateY(-50%);
            text-align: left;
            background: $social-colour;
            margin: 0;
            text-transform: uppercase;
            color: #fff;
            font-size: 18px;

            opacity: 0;
            right: 0;
            pointer-events: none;
            user-select: none;
            transition: .3s;
            z-index: -1;

            @media only screen and (max-width: 990px) {
                display: none;
            }
        }

        &:hover {
            .tooltip {
                right: 40px;
                opacity: 1;
            }
        }
    }
}

// ----------------------------------- GOOGLE TRANSLATE WIDGET

#google_translate_element {
    opacity: 0;
}

.translate {
    @include box(30px);
    overflow: hidden;
    background: url(/i/design/translate-ic.png) no-repeat center;
    z-index: 99;
}

.goog-te-gadget .goog-te-combo {
    padding: 10px;
}

// ----------------------------------- SEARCH FORM

a#FS {
    @include box(30px);
    background: url(/i/design/search-ic.png) no-repeat center;
    padding: 0px;
    transition: all .3s ease;
    cursor: pointer;
}

/* Search Style */
#search {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    transition: all 0.5s ease-in-out;
    transform: translate(0px, -100%) scale(0, 0);
    opacity: 0;
    display: none;
}

#search.open {
    transform: translate(0px, 0px) scale(1, 1);
    opacity: 1;
    z-index: 9999999;
    display: block;
}

#search input[type="search"] {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -51px;
    width: 60%;
    margin-left: 20%;
    color: rgb(255, 255, 255);
    background: transparent;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    font-size: 40px;
    font-weight: 300;
    text-align: center;
    outline: none;
    padding: 10px;

    @media only screen and (max-width: 990px) {
        width: 70%;
        max-width: 250px;
        background: #fff;
        border-radius: 40px;
        padding: 20px;
        color: #111;
        font-size: 15px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -50px auto 0;
        outline: none;
        box-shadow: 0 0 0 3px #cccccc;
    }
}