// ----------------------------------- WELCOME SEC

#welcome-sec {
 position: relative;
 @extend .clearfix;
 overflow: hidden;

 &:before {
  @include before(100%, 40px);
  right: 0;
  left: 0;
  margin: 0 auto;
  bottom: -2px;
  background: url(/i/design/white-curve-full.png) no-repeat center;
  z-index: 99;
 }

 .wrapper {
  width: 55%;
  float: left;
  padding: 70px 5% 100px 8%;
  background: $welcome-bg;

  @media only screen and (max-width: 1800px) {
   width: 60%;
   padding: 70px 5% 100px 7%;
  }

  @media only screen and (max-width: 1500px) {
   width: 65%;
   padding: 60px 5% 90px 7%;
  }

  @media only screen and (max-width: 1350px) {
   padding: 50px 0 80px 5%;
  }

  @media only screen and (max-width: 990px) {
   float: none;
   width: 100%;
   padding: 50px 0;
  }

  &:before {
   @include before(100%, 100%);
   right: -95%;
   top: 0;
   background: url(/i/graphics/welcome-img.jpg) no-repeat top center;
   background-size: cover;

   @media only screen and (max-width: 990px) {
    display: none;
   }
  }

  .text-wrap {
   width: calc(100% - 150px);
   margin: 0 0 0 60px;
   color: $welcome-text;
   font-size: 22px;

   @media only screen and (max-width: 1800px) {
    font-size: 21px;
    line-height: 1.2;
   }

   @media only screen and (max-width: 1500px) {
    font-size: 20px;
   }

   @media only screen and (max-width: 990px) {
    width: 100%;
    padding: 0 5%;
    margin: 0 auto 50px;
    line-height: 1.6;
   }

   p {
    letter-spacing: .5px;
   }

   .read-more {
    @media only screen and (max-width: 990px) {
     float: none;
    }
   }
  }
 }

 h2 {
  margin: 0 0 50px 0;
  position: relative;
  color: $welcome-text;
  line-height: 1;
  font-size: 75px;

  @media only screen and (max-width: 1800px) {
   font-size: 68px;
  }

  @media only screen and (max-width: 1500px) {
   font-size: 55px;
  }

  @media only screen and (max-width: 1350px) {
   font-size: 40px;
  }

  span {
   display: block;
   font-size: 50px;

   @media only screen and (max-width: 1800px) {
    font-size: 45px;
   }

   @media only screen and (max-width: 1500px) {
    font-size: 40px;
   }

   @media only screen and (max-width: 1350px) {
    font-size: 25px;
   }

  }

  &:before {
   @include before(120px, 2px);
   left: 0;
   bottom: -30px;
   background: #fff;
  }
 }

 .read-more {
  &:hover {
   transform: translateY(-5px);
  }
 }
}

// -----------------------------------

#btns-sec {
 padding: 50px 0;

 ul {
  text-align: center;

  li {
   display: inline-block;
   padding: 0;
   background: none;
   overflow: hidden;
   margin: 0 10px;
   position: relative;
   transition: .3s;
   width: 385px;
   max-width: calc(100% / 4 - 50px);
   outline: 5px solid #fff;
   outline-offset: -3px;

   @media only screen and (max-width: 990px) {
    max-width: 95%;
    width: 330px;
    margin: 10px;
    border-radius: 20px;
   }

   &:before {
    @include before(100%, 50px);
    background: url(/i/design/btn-curve-white.png) no-repeat top center;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    pointer-events: none;
    user-select: none;
   }

   &:after {
    @include before(100%, 50px);
    background: url(/i/design/btn-curve-white-flip.png) no-repeat bottom center;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    pointer-events: none;
    user-select: none;
   }

   .thing {
    position: absolute;
    width: calc(100% - 6px);
    height: 150px;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 2px;
    pointer-events: none;
    user-select: none;

    &:before {
     @include before(100%, 100%);
     background: linear-gradient(0deg, rgba(#000, 1) 0%, rgba(#000, 0) 100%);
     top: 0;
     left: 0;
     pointer-events: none;
     user-select: none;
    }
   }

   img {
    transition: .3s;
   }

   span.text-span {
    @include center;
    top: 80%;
    color: #fff;
    width: 100%;
    font-size: 45px;
    z-index: 99;
    transition: .3s;


    @media only screen and (max-width: 1800px) {
     font-size: 40px;
    }

    @media only screen and (max-width: 1500px) {
     font-size: 35px;
    }

    @media only screen and (max-width: 1350px) {
     font-size: 30px;
    }
   }

   &:hover {

    span.text-span {
     top: 70%;
    }
   }
  }

  &:hover li:not(:hover) {
   opacity: .5;
   transform: scale(.95);
   // filter: blur(2px);
  }
 }
}

#curriculum-sec {
 padding: 100px 0 180px;
 background: $curr-bg;
 position: relative;

 @media only screen and (max-width: 990px) {
  padding: 50px 0 80px;
 }

 &:before {
  @include before(100%, 86px);
  right: 0;
  left: 0;
  margin: 0 auto;
  top: -2px;
  background: url(/i/design/white-curve-full-thick.png) no-repeat center;
  z-index: 99;
 }

 h2 {
  text-align: center;
  margin: 0 auto 50px;
  color: $curr-text;
  font-size: 75px;

  @media only screen and (max-width: 1800px) {
   font-size: 68px;
  }

  @media only screen and (max-width: 1500px) {
   font-size: 55px;
   margin: 0 auto 40px;
  }

  @media only screen and (max-width: 1350px) {
   font-size: 40px;
   margin: 0 auto 30px;
  }
 }

 p {
  color: $curr-text;
  font-size: 22px;
  text-align: center;
 }
}

// -----------------------------------//
// EMPTY QUEERS
// -----------------------------------//

@media only screen and (max-width: 1800px) {}

@media only screen and (max-width: 1500px) {}

@media only screen and (max-width: 1350px) {}

@media only screen and (max-width: 990px) {}

@media only screen and (max-width: 650px) and (orientation:landscape) {}

// -----------------------------------//
// BROWSER SPECIFIC
// -----------------------------------//

// IE fixes
@media all and (-ms-high-contrast: none),
all and (-ms-high-contrast: active),
screen\0,
screen\9 {}

// Edge fixes
@supports (-ms-ime-align:auto) {}