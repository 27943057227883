	// -----------------------------------//
	// FOOTER
	// -----------------------------------//

	footer {
		margin: 0 auto;
		clear: both;
		background: $footer-bg;
		padding: 80px 0 60px;
		position: relative;

		@media only screen and (max-width: 990px) {
			height: auto;
			padding: 50px 0;
		}

		&:before {
			@include before(100%, 90px);
			right: 0;
			left: 0;
			margin: 0 auto;
			top: -45px;
			background: url(/i/design/double-ender-full.png) no-repeat center;
			z-index: 99;
		}

		.wrapper {
			text-align: left;
			font-size: 22px;
			width: 1130px;

			@media only screen and (max-width: 1500px) {
				font-size: 20px;
			}

			@media only screen and (max-width: 990px) {
				width: 95%;
				padding: 0 5%;
			}
		}

		.footer-heading {
			padding: 0;
			margin: 0 0 20px 230px;
			color: #fff;
			font-size: 60px;

			@media only screen and (max-width: 990px) {
				text-align: center;
				margin: 0 auto 20px;
				display: block;
				float: none;
				font-size: 40px;
			}
		}

		p,
		a[href^="mailto:"] {
			margin: 0;
			color: #fff;

			@media only screen and (max-width: 990px) {
				text-align: center;
				margin: 0 auto 10px;
				display: block;
				float: none;
			}

			span:not(.p-inner) {
				display: block;
				color: $details-span;
				font-size: 30px;

				@media only screen and (max-width: 1500px) {
					font-size: 24px;
				}

				@media only screen and (max-width: 990px) {
					font-size: 20px;
				}
			}
		}

		a {
			@media only screen and (max-width: 990px) {
				word-break: break-all;
			}
		}

		.seeat {
			margin: 0 70px 0 0;

			@media only screen and (max-width: 1250px) {
				margin: 0 60px 0 50px;
			}

			@media only screen and (max-width: 1100px) {
				// margin: 0 100px 0 50px;
			}


			@media only screen and (max-width: 990px) {
				margin: 0 auto 30px;
				display: block;
				width: 98px;
			}
		}

		.address {
			margin: 0 150px 0 0;

			@media only screen and (max-width: 1250px) {
				margin: 0 30px 0 0;
			}

			@media only screen and (max-width: 990px) {
				margin: 0 auto 30px;
			}

			img {
				margin: 15px 80px 0 0;

				@media only screen and (max-width: 1250px) {
					margin: 15px 30px 0 0;
				}

				@media only screen and (max-width: 990px) {
					margin: 0 auto 30px;
					display: block;
					width: 108px;
				}
			}
		}

		.seeat,
		.details {
			display: inline-block;
			vertical-align: middle;

			@media only screen and (max-width: 990px) {
				display: block;
			}
		}

		.address,
		.deet-inner {
			display: inline-block;
			vertical-align: top;

			@media only screen and (max-width: 990px) {
				display: block;
			}
		}

		.p-inner,
		.address img {
			display: inline-block;
			vertical-align: top;

			@media only screen and (max-width: 990px) {
				display: block;
			}
		}

		.address {
			strong {
				display: block;
			}
		}

		.telephone {
			margin: 0 0 20px 0;
		}

		a[href^="mailto:"] {
			display: inline-block;
			position: relative;

			@media only screen and (max-width: 990px) {
				display: block;
				max-width: 180px;
			}

			&:before {
				@include before(0, 2px);
				border-radius: 10px;
				background: #fff;
				transition: .3s;
				left: 0;
				bottom: -5px;
			}

			&:hover {
				&:before {
					width: 100%;
				}
			}
		}
	}

	// -----------------------------------//
	// SUB-FOOTER
	// -----------------------------------//

	.sub-footer {
		height: 80px;
		line-height: 80px;
		background: $footer-bg;
		overflow: hidden;
		@extend .clearfix;
		position: relative;

		@media only screen and (max-width: 990px) {
			height: auto;
			line-height: normal;
			padding: 5px 0;
		}

		&:before {
			@include before(100px, 1px);
			left: 0;
			right: 0;
			margin: 0 auto;
			top: 0;
			background: $details-span;
		}

		.wrapper {
			width: 600px;

			@media only screen and (max-width: 990px) {
				width: 100%;
			}
		}

		p {
			font-size: 15px;
			color: #fff;
			margin: 0;
			letter-spacing: .5px;

			@media only screen and (max-width: 990px) {
				text-align: center;
				margin: 5px auto;
			}
		}

		#copyright {
			float: left;

			@media only screen and (max-width: 990px) {
				float: none;
			}
		}

		#credit {
			float: right;

			@media only screen and (max-width: 990px) {
				float: none;
			}
		}

		#copyright,
		#credit {


			a {
				color: #fff;

				@media only screen and (max-width: 990px) {
					margin: 0;
					display: inline-block;
				}

				&:last-of-type {
					color: $details-span;
					text-decoration: underline;
				}
			}
		}
	}

	// -----------------------------------//
	// TOP BUTTON
	// -----------------------------------//

	.top-btn {
		position: fixed;
		z-index: 99999;
		bottom: 50px;
		right: 30px;
		display: block;
		// width: 45px;
		// height: 45px;
		background: rgba(0, 0, 0, .3);
		border-radius: 50%;
		color: #fff;
		font-size: 25px;
		text-align: center;
		opacity: 0;
		transform: scale(0);
		transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

		@media only screen and (max-width: 1199px) {
			display: none;
		}
	}

	.show-top-btn {
		opacity: 1;
		transform: scale(1);
	}

	// -----------------------------------//
	// AWARDS
	// -----------------------------------//

	#awards {
		width: 100%;
		overflow: hidden;
		background: #fff;
		padding: 20px 0;

		.CMSawards {
			overflow: hidden;
			width: 100%;
			height: 100%;
			margin: 0 auto !important;
			background: none !important;

			ul {
				margin: 0;

				li {
					background: none;
					padding: 0 5px;
					margin: 0 10px;
					display: inline-block;

					img {
						max-height: 70px;
						transform: none !important;
					}
				}
			}
		}

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}